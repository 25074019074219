import { Form, InputNumber, Select, TimePicker, Tooltip, type FormInstance } from 'antd'
import { useContext, useEffect, useState } from 'react'
import FormItem from 'antd/es/form/FormItem'
import dayjs from 'dayjs'
import CustomTimePicker from '../../CustomPickerTime'
import { TimeRules } from '../../../constants/timerules'

interface Props {
    waypoints: ValuesWaypoints[]
    [x: string]: any
}

interface SpecificWpProps {
    waypoints: ValuesWaypoints[]
}

/**
 * Different components of the form that need the waypoints to work
 * @param {SpecificWpProps} Object Object to construct the component
 * @returns Different form items
 */
const BaseSpecificWaypointForm = ({ waypoints }: SpecificWpProps) => {
    const defaultMinTimeInZone = 0
    const last = waypoints.length > 0 ? waypoints[waypoints.length - 1]?.id : undefined

    return (
        <>
            <Form.Item
                name={['threshold', 'waypoint']}
                label={
                    <div>
                        <Tooltip title="Radius of the maximum radius that the participant can leave the track in meters">
                            Radius
                        </Tooltip>
                    </div>
                }
                className="mb-0"
                initialValue={25}
            >
                <InputNumber className=" w-full" min={1} defaultValue={25} precision={0}></InputNumber>
            </Form.Item>
            <FormItem
                name="firstWp"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="First waypoint to take into account in the report">First wp</Tooltip>
                    </div>
                }
                initialValue={waypoints[0]?.id}
            >
                <SelectFirstWp waypoints={waypoints} />
            </FormItem>
            <FormItem
                name="lastWp"
                className="m-0 w-full"
                label={
                    <div>
                        <Tooltip title="Last waypoint to take into account in the report">Last wp</Tooltip>
                    </div>
                }
                initialValue={last}
            >
                <SelectLastWp waypoints={waypoints} />
            </FormItem>

            <Form.Item
                name="minTimeInZone"
                label={
                    <div>
                        <Tooltip title="Time you have to complete as many waypoints as possible.">Max Time</Tooltip>
                    </div>
                }
                rules={TimeRules}
                className="m-0 "
                initialValue={defaultMinTimeInZone}
            >
                <CustomTimePicker defaultMinTimeInZone={defaultMinTimeInZone} />
            </Form.Item>
        </>
    )
}

const SelectFirstWp = ({ waypoints, ...restProps }: Props) => {
    const [firstWaypointValue, setFirstWaypointValue] = useState<any>(waypoints[0]?.id)

    useEffect(() => {
        restProps.onChange(waypoints[0]?.id)
        setFirstWaypointValue(waypoints[0]?.id)
    }, [waypoints])

    return (
        <Select
            className="w-full"
            value={firstWaypointValue}
            size="small"
            options={waypoints.map(e => {
                return {
                    value: e.id,
                    label: e.name,
                }
            })}
            onChange={value => setFirstWaypointValue(value)}
            {...restProps}
        />
    )
}

const SelectLastWp = ({ waypoints, ...restProps }: Props) => {
    const last = waypoints.length > 0 ? waypoints[waypoints.length - 1]?.id : undefined
    const [lastWaypointValue, setLastWaypointValue] = useState<any>(last)

    useEffect(() => {
        restProps.onChange(last)
        setLastWaypointValue(last)
    }, [waypoints])

    return (
        <Select
            className="w-full"
            value={lastWaypointValue}
            size="small"
            options={waypoints.map(e => {
                return {
                    value: e.id,
                    label: e.name,
                }
            })}
            onChange={value => setLastWaypointValue(value)}
            {...restProps}
        />
    )
}
export default BaseSpecificWaypointForm
