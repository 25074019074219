import { Input } from 'antd'
import type dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { timespanToHHMMSS } from '../../utils'

/**
 * @param defaultMinTimeInZone Default minTimeInZone
 * @param x Remaining props that take the component from the parent component
 */
interface PropsTimePicker {
    defaultMinTimeInZone: any
    [x: string]: any
}
/**
 * Custom time pickcer that works in seconds
 * @param {PropsTimePicker} object Objecto to contruct the component
 * @returns
 */
const CustomTimePicker = ({ defaultMinTimeInZone, ...restProps }: PropsTimePicker) => {
    const [value, setValue] = useState(defaultMinTimeInZone)

    useEffect(() => {
        setValue(timespanToHHMMSS(defaultMinTimeInZone))
    }, [defaultMinTimeInZone])

    useEffect(() => {
        if (value !== timespanToHHMMSS(restProps.value) && timespanToHHMMSS(restProps.value) !== 'Invalid time') {
            setValue(timespanToHHMMSS(restProps.value))
        }
    }, [restProps.value])

    useEffect(() => {
        if (/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(value)) {
            const time = value.split(':')
            const totalSeconds = Number(time[0]) * 3600 + Number(time[1]) * 60 + Number(time[2])
            restProps.onChange(totalSeconds)
        } else {
            restProps.onChange(null)
        }
    }, [value])

    return (
        <Input
            {...restProps}
            className="w-full"
            value={value}
            size="small"
            onChange={e => {
                setValue(e.target.value)
            }}
        />
    )
}
export default CustomTimePicker
// https://empresite.eleconomista.es/ANUBE-IDI.html
