import type { Rule } from 'antd/es/form'

export const TimeRules: Rule[] = [
    {
        validator: (_, value) => {
            if (value !== null) {
                return Promise.resolve()
            } else {
                return Promise.reject(new Error('Invalid Time'))
            }
        },
        message: 'Invalid Time',
    },
]
