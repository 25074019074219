import { Button, Card, Collapse, CollapseProps, Input, Modal } from 'antd'
import React, { useState } from 'react'
import './summary_card.css'
import { Item, Menu, useContextMenu } from 'react-contexify'
import 'react-contexify/ReactContexify.css'
import EMAIL from '../../res/email.png'
const MENU_ID = 'blahblah'
import emailjs from '@emailjs/browser'
import TextArea from 'antd/es/input/TextArea'

interface Props {
    number: string
    rallyId: number
    type: string
    onClick: () => void
    className: string
    children?: any
    collapseData?: any
}
const ParticipantCard = ({ className, number, type, onClick, children, collapseData, rallyId }: Props) => {
    const selectedValue = className.includes('selected') && collapseData ? 1 : 0
    const [openModalEmail, setOpenModalEmail] = useState(false)
    const [emailMessage, setEmailMesagge] = useState('')
    const { show } = useContextMenu({
        id: MENU_ID,
    })
    const onContextMenu = (e: any) => {
        show({
            event: e,
        })
    }
    const handleEmailClick = ({ id, event, props }: any) => {
        switch (id) {
            case 'email':
                setOpenModalEmail(true)
                break
        }
    }
    const handleSendEmailButton = () => {
        try {
            emailjs.send(
                process.env.REACT_APP_EMAILSERVICEID!,
                process.env.REACT_APP_EMAILTEMPLATEID!,
                {
                    rallyId: rallyId,
                    type: type,
                    participantNumber: number,
                    message: emailMessage,
                },
                {
                    publicKey: process.env.REACT_APP_EMAILPUBLICKEY,
                }
            )
        } catch (error: any) {
            console.error(error)
        }

        setOpenModalEmail(false)
    }
    const collapseItems: CollapseProps['items'] = [
        {
            key: '1',
            label: (
                <>
                    <div
                        className={`${className} summary-card summary-card-content m-1 flex h-full flex-row  rounded border  border-solid border-neutral-300 bg-neutral-100 shadow-sm`}
                        onClick={onClick}
                        onContextMenu={onContextMenu}
                    >
                        <div className="text-md w-11 self-center text-center">#{number}</div>
                        {children}
                    </div>
                    <Menu id={MENU_ID}>
                        <Item id="email" onClick={handleEmailClick}>
                            <>
                                <img src={EMAIL} />
                                Send Email Report
                            </>
                        </Item>
                    </Menu>
                    <Modal
                        styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
                        onCancel={() => setOpenModalEmail(false)}
                        title="Email Message"
                        open={openModalEmail}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                    >
                        <div className="flex space-x-2">
                            <TextArea
                                showCount
                                maxLength={200}
                                onChange={e => setEmailMesagge(e.target.value)}
                                placeholder="Can resize"
                            />
                            <Button onClick={handleSendEmailButton}>Send!</Button>
                        </div>
                    </Modal>
                </>
            ),
            children: collapseData,
            showArrow: false,
        },
    ]

    return (
        <>
            <Collapse className=" h-full" activeKey={selectedValue} items={collapseItems} bordered={false}></Collapse>
        </>
    )
}
export default ParticipantCard
