import React, { useState } from 'react';

import '../summary_card.css';
import RETRY_HOVERED_DISABLED from "../../../res/retry-hovered-disabled.png" 
import RETRY_DISABLED from"../../../res/retry-disabled.png" 
import RETRY_HOVERED from "../../../res/retry-hovered.png" 
import RETRY from "../../../res/retry.png" 

interface Props {
    errorResults :  AnalyzerResult[] ,
    disabled : boolean ,
    onRetry : (participantIds :  AnalyzerResult[]) => void
}
const ErrorsCard = ({disabled,errorResults,onRetry}:Props) => {
    const [retryHover, setRetryHover] = useState(false);


    if (!errorResults.length) {
        return null; 
    }

    let icon;
    if (disabled) {
        icon = retryHover ? RETRY_HOVERED_DISABLED : RETRY_DISABLED;
    } else {
        icon = retryHover ? RETRY_HOVERED : RETRY;
    }

    return (
        <div className='errors-card'>
            <b>Could not get data for the following participants:</b><br />
            {errorResults.map((e : AnalyzerResult) => parseInt(e.number )).sort((a: number, b: number) => a - b).map((e: number) => "#" + e).join(", ")}

            <div className='errors-card-retry'>
                <img
                    src={icon}
                    alt="Retry"
                    onMouseEnter={() => setRetryHover(true)}
                    onMouseLeave={() => setRetryHover(false)}
                    onClick={() => {
                        if (!disabled) {
                            onRetry(errorResults);
                            setRetryHover(false);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ErrorsCard;
