import ParticipantCard from '../participantCard'
import RangeBar from '../../RangeBar/rangeBar'

import '../summary_card.css'

const OppositeCard = ({ className, onClick, result }: any) => {
    let width
    try {
        width = result.data.unmatchedChunks[0].total
    } catch (e) {
        width = 1
    }
    const ranges = result.data.unmatchedChunks.map((unmatch: any) => {
        return {
            edges: unmatch.range!,
            disabled: unmatch.disabled || false,
        }
    })
    const differences = (result.data as any).info

    let diffsFormatted = null
    if (differences.length > 0) {
        diffsFormatted = differences.map((difference: any, index: number) => {
            const formatDifference: string = difference.trajectoryDistance.toFixed(0)
            return (
                <div key={difference} style={{ display: 'flex' }}>
                    {`Opposite direction Nº ${index + 1} : `}
                    <div style={{ width: `50px`, textAlign: 'right' }}>{formatDifference} m</div>
                </div>
            )
        })
    }
    return (
        <ParticipantCard
            rallyId={result.rally}
            number={result.number}
            type='Opposite direction'
            onClick={() => onClick(result)}
            className={`${className} track-card`}
            collapseData={diffsFormatted}
        >
            <RangeBar inactiveStart={100} theme="opposite" ranges={ranges} width={width} />
        </ParticipantCard>
    )
}

export default OppositeCard
