import { Collapse, type CollapseProps } from 'antd'
import { timeUtcToHHMMSS } from '../../../utils'
import ParticipantCard from '../participantCard'

type Props = {
    result: GenericSpeedResult
    number: string
    onClick: (a: GenericSpeedResult) => void
    className: string
    timezone: string
    updatePointer: (lon: number, lat: number) => void
}
const GenericSpeedCard = ({ onClick, result, number, className, timezone, updatePointer }: Props) => {
    const genericSpeed = result.data.genericSpeed
    genericSpeed.failed
    const totalInfringements = genericSpeed.infringementCount
    let totalPenalty = 0
    for (let i of genericSpeed.infringements) totalPenalty += i.penalty

    const generaricSpeed = (
        <div className="mr-2 flex w-full flex-row justify-end space-x-1 text-nowrap ">
            {genericSpeed.failed && (
                <>
                    <div>Infringements:</div>
                    <div
                        className={`w-[50px] rounded ${genericSpeed.infringementCount > 0 ? 'bg-[red]' : ''} text-center`}
                    >
                        {' '}
                        {totalInfringements}
                    </div>
                    <div className="italic">Penalizations:</div>
                    <div
                        className={`w-[50px] rounded ${genericSpeed.infringementCount > 0 ? 'bg-[red]' : ''} text-center`}
                    >
                        {totalPenalty} {genericSpeed.penaltyUnit}
                    </div>
                </>
            )}
            <div>Max Speed :</div>

            {genericSpeed.maxSpeed ? (
                <div
                    className={`w-[60px] rounded  ${genericSpeed.infringementCount > 0 ? 'bg-[red]' : 'bg-[green]'} text-center `}
                >
                    {genericSpeed.maxSpeed} km/h
                </div>
            ) : (
                <div className={`min-w-[60px] rounded bg-blue-200 text-center `}>--</div>
            )}
        </div>
    )

    const zones = (
        <div>
            {genericSpeed.infringements.map((e: any) => {
                return (
                    <div className=" border-solid-1 m-1 flex flex-row justify-center text-nowrap rounded border border-neutral-300 bg-neutral-200 text-right text-xs">
                        <div className="flex flex-row space-x-1 self-center rounded hover:cursor-pointer">
                            <div
                                onClick={() => {
                                    updatePointer(e.participant[0], e.participant[1])
                                }}
                            >
                                {timeUtcToHHMMSS(e.participant[2], timezone)}
                            </div>
                        </div>
                        <div className="flex  flex-row space-x-1">
                            <span className="p-1 font-bold italic underline">Max speed:</span>
                            <div className="rounded bg-[red] p-1">{e.participant[3]} km/h</div>
                        </div>
                        <div className="flex  flex-row space-x-1 ">
                            <span className="p-1 font-bold italic underline ">Penalization:</span>
                            <div className="p-1">
                                {e.penalty} {genericSpeed.penaltyUnit}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

    return (
        <ParticipantCard
            rallyId={result.rally}
            number={number}
            onClick={() => onClick(result)}
            className={className}
            collapseData={zones}
            type='Generic max speed'
        >
            {generaricSpeed}
        </ParticipantCard>
    )
}
export default GenericSpeedCard
