import MatchTrackHandler from './matchTrackHandler'
import { trackHandler } from './matchTrackAlgorithm'
import Analyzer from '../analyzer'
import { WorkerPool } from '../workerPool'
import { handlerFiltersPutBrand, objectContains } from '../../utils'
import { putFiltersBrand } from '../../outtrack/rallyrestapi'
export default class MatchTrackAnalyzer extends Analyzer {
    resultHandler: MatchTrackHandler
    matchTrackResults: MatchTrackResult[]

    constructor(rallyId: number, section: Section, excluded: any, connections: Devices, routesReports: any) {
        super(rallyId, section, excluded, connections, routesReports)

        this.resultHandler = new MatchTrackHandler(this, section, excluded)
        this.matchTrackResults = []
        this.onResults = () => {}
    }

    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'match')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any
    ) => {
        const specificParams = this._getRequestParamsSpecific(data)
        const brands = await handlerFiltersPutBrand(
            this.rallyId,
            this.section.section,
            token,
            data.detectionType,
            specificParams,
            brandFilters
        )
        if (brands) return brands

        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections
            )

            const worker = await this._pool.get()
            if (worker == null) {
                break
            }
            worker.onmessage = async (e: MessageEvent<MessageAlgorithm>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'matchTrackResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: TrackReport = e.data.result
                        this.resultHandler.excludeOutliers(resultInfo, this.excluded)
                        this.resultHandler.setUnmatchedTrackRanges(resultInfo)
                        const areaPoints: AreaPoints[] = this.resultHandler.getAreaPoints(resultInfo)

                        const customTracks: number[][] = this.resultHandler.getLines(resultInfo)
                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: MatchTrackResult = {
                            highlightedPoints: [{}, {}],
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.rallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: areaPoints,
                            customTracks: customTracks,
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.matchTrackResults = [...this.matchTrackResults, result]
                        this.onResults(this.matchTrackResults)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }

            worker.postMessage(['matchTrackProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.matchTrackResults
    _getRequestParamsSpecific = (data: DataRallyForm) => {
        return {
            threshold: { match: data.detectionTypeData.threshold.match },
        }
    }
    _getRequestParams = (
        rallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: Devices
    ): ParamsAnalyzerMatchTrack => {
        const reqData: ParamsAnalyzerMatchTrack = {
            token: token,
            rally: rallyId,
            section: section,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            threshold: data.detectionTypeData.threshold.match,
            participant: participant,
        }

        return reqData
    }
}
